@import "../../../../../../scss/variables/colors";
@import "../../../../../../scss/common";

.rcw-messages-container {
  background-color: $white;
  height: 50vh;
  max-height: 410px;
  overflow-y: scroll;
  padding-top: 10px;
  -webkit-overflow-scrolling: touch;
}

.rcw-full-screen {
  .rcw-messages-container {
    @include messages-container-fs;
  }
}

@media screen and (max-width: 800px) {
  .rcw-messages-container {
    @include messages-container-fs;
  }
}
