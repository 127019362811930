@import "../../../../scss/common";
@import "../../../../scss/variables/colors";
@import "../../../../scss/animation";

.rcw-launcher {
  @include animation(0, 0.5s, slide-in);
  align-self: flex-end;
  background-color: $turqois-1;
  border: 0;
  border-radius: 50%;
  box-shadow: 0px 2px 10px 1px $grey-3;
  height: 60px;
  margin-top: 10px;
  cursor: pointer;
  width: 60px;

  &:focus {
    outline: none;
  }
}

.rcw-open-launcher {
  @include animation(0.01s, 0.5s, rotation-rl);
}

.rcw-close-launcher {
  @include animation(0.01s, 0.5s, rotation-lr);
}

@media screen and (max-width: 800px) {
  .rcw-launcher {
    @include launcher-fs;
  }

  .rcw-hide-sm {
    display: none;
  }
}
