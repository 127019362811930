@import "../../../../../../scss/variables/colors";

.rcw-launcher {
  .rcw-badge {
    position: fixed;
    top: -10px;
    right: -5px;
    background-color: $red;
    color: $white;
    width: 25px;
    height: 25px;
    text-align: center;
    line-height: 25px;
    border-radius: 50%;
  }
}
