@import "../../../../../../scss/variables/colors";
@import "../../../../../../scss/common";

.rcw-conversation-container {
  .rcw-header {
    background-color: $turqois-1;
    border-radius: 10px 10px 0 0;
    color: $white;
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 15px 0 25px;
  }

  .rcw-title {
    font-size: 24px;
    margin: 0;
    padding: 15px 0;
  }

  .rcw-close-button {
    display: none;
  }

  .avatar {
    border-radius: 100%;
    vertical-align: middle;
  }
}

.rcw-full-screen {
  .rcw-header {
    @include header-fs;
  }

  .rcw-title {
    @include title-fs;
  }

  .rcw-close-button {
    @include close-button-fs;
  }

  .rcw-close {
    @include close-fs;
  }
}

@media screen and (max-width: 800px) {
  .rcw-conversation-container {
    .rcw-header {
      @include header-fs;
    }

    .rcw-title {
      @include title-fs;
    }

    .rcw-close-button {
      @include close-button-fs;
    }

    .rcw-close {
      @include close-fs;
    }
  }
}
